
.eCommerce-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.bottom-btn {
    text-align: center;
    margin: 40px 0;
}
